import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import containerStyles from "../components/main.module.scss"

import logo from '../images/logo.svg';

const Icon = () => (
  <svg style={{ maxWidth:720, }}>
    <use  xlinkHref={`#${logo.id}`} />
  </svg>
)

const NotFoundPage = () => (
  <Layout>
  <div className={containerStyles.innercontainer}>
		<Icon></Icon>
    <SEO title="404: Not found" />
    <h2>NOT FOUND</h2>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
